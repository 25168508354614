import React from 'react';

import PIconView from './PIcon.view';

import icons from '../../assets/icons';
import { IconSize } from './PIcon.model';

type Props = {
  name: keyof typeof icons;
  className?: string;
  size?: IconSize;
};

const PIcon: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return <PIconView name={props.name} className={props.className} size={props.size}></PIconView>;
};

PIcon.displayName = 'PIcon';
PIcon.defaultProps = {};

export default PIcon;
