export enum Path {
  Welcome = 'welcome',
  Dashboard = 'dashboards',
  Summary = 'summary',
  NewsFeeds = 'news-feeds',
  Reports = 'reports',
  SensorsDashboard = 'sensors-dashboard',
  IrBehaviouralAnalysis = 'ir-analysis',
  IocLookup = 'ioc-lookup',
  ThreatIntelSearch = 'threatintel-search',
  IncidentsActive = 'incidents-active',
  IncidentsHistory = 'incidents-history',
  Integrations = 'integrations',
  Secrets = 'secrets',
  Resources = 'resources',
  OrganizationsSettings = 'org-settings',
  UsersSettings = 'user-settings',
  AdminSettings = 'admin-page',
  Logout = 'logout',
  ConditionalAccess = 'conditional-access',
  ProspectManagement = 'prospect',
  CVE = 'cve-2024-3094',
  AccountSettings = 'account-settings',
  Warp = 'file-sharing-status',
  Upload = 'file-sharing-usage',
  AuditOrg = 'audit-org',
  AuditSelf = 'audit-self',
  Findings = 'findings',
  Health = 'health',
  Usage = 'usage',
}
