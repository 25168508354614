const resources = {
  title: 'resources',
  newResourceBtn: 'add resource',
  noResults: 'No Results',
  searchInResources: 'Search in resources',

  cardData: {
    overview: 'overview',
    downloads: 'downloads',

    actions: {
      copy: {
        tooltipText: 'copy download link',
        copied: 'download link copied successfully',
      },

      edit: {
        tooltip: 'edit resource',

        modal: {
          header: 'heads up: you are about to modify a resource',
          body: 'You are about to modify a resource by the name of: <strong>{{name}}</strong>.</br><strong>Are you sure you want to modify it?</strong>',
          confirmText: 'yes, continue',
          deleteText: 'cancel',
        },
      },

      downloadAll: {
        tooltip: 'DOWNLOAD ALL FILES',
      },
    },

    table: {
      headers: {
        architecture: 'arch.',
        display_name: 'file name',
        checksum: {
          title: 'checksum',
          explentation: 'SHA 256',
        },
        size: 'file size',
      },

      cells: {
        display_name: {
          download: 'download file',
        },
      },
    },
  },

  form: {
    create: {
      title: 'Add New Resource',

      addField: 'add field',

      fields: {
        resource_name: {
          display: 'Resource Name',
          placeholder: 'Add resource name',
        },

        version: {
          display: 'Version',
          placeholder: 'Ex. 1.0.0',
          validation: 'Text is not valid',
        },

        display_name: {
          display: 'display name',
          placeholder: 'Add display name',
          validation: 'Text is not valid, please use 0-9 a-z A-Z _-.only',
        },

        os: {
          display: 'os',
          placeholder: 'Add os',
          validation: 'Text is not valid, please use 0-9 a-z A-Z _-.only',

          osOptions: {
            windows: 'Windows',
            linux: 'Linux',
            mac: 'MacOS',
            none: 'None',
          },
        },

        architecture: {
          display: 'architecture',
          placeholder: 'Add architecture',
          validation: 'Text is not valid, please use 0-9 a-z A-Z _-.only',

          archOptions: {
            bit64: '64bit',
            arm64: 'Arm64',
            none: 'None',
          },
        },

        description: {
          display: 'summary (UP TO 450 CHARCTERS)',
          placeholder: 'Add description',
        },

        uploadFile: {
          display: 'Upload File (max file size: 25 mb)',
        },
      },

      footer: {
        confirm: 'Add Resource',
        cancel: 'Cancel',
      },

      successToast: {
        message: 'New resource added',
      },

      errorToast: {
        message: 'Could not add resource',
      },
    },

    edit: {
      title: 'Edit Resource',

      addField: 'add field',

      fields: {
        resource_name: {
          display: 'Resource Name',
          placeholder: 'Add resource name',
        },

        version: {
          display: 'Version',
          placeholder: 'Ex. 1.0.0',
          validation: 'Text is not valid',
        },

        display_name: {
          display: 'display name',
          placeholder: 'Add display name',
          validation: 'Text is not valid, please use 0-9 a-z A-Z _-.only',
        },

        os: {
          display: 'os',
          placeholder: 'Add os',
          validation: 'Text is not valid, please use 0-9 a-z A-Z _-.only',

          osOptions: {
            windows: 'Windows',
            linux: 'Linux',
            mac: 'MacOS',
            none: 'None',
          },
        },

        architecture: {
          display: 'architecture',
          placeholder: 'Add architecture',
          validation: 'Text is not valid, please use 0-9 a-z A-Z _-.only',

          archOptions: {
            bit32: '32bit',
            bit64: '64bit',
            none: 'None',
          },
        },

        description: {
          display: 'summary (UP TO 450 CHARCTERS)',
          placeholder: 'Add description',
        },

        uploadFile: {
          display: 'Upload File (max file size: 25 mb)',
        },
      },

      footer: {
        confirm: 'Save',
        cancel: 'Cancel',
      },

      successToast: {
        message: 'Edit Successful',
      },

      errorToast: {
        message: 'Could not edit resource',
      },
    },
  },
};

export default resources;
