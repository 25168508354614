import React, { useEffect, useState } from 'react';

import PSpinnerView from './PSpinner.view';

import { SpinnerSize } from './PSpinner.model';

type Props = {
  size: SpinnerSize;
  className?: string;
};

const PSpinner: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const [windowWidthState, setWindowWidthState] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidthState(() => window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return <PSpinnerView size={props.size} windowWidth={windowWidthState} className={props.className}></PSpinnerView>;
};

PSpinner.displayName = 'PSpinner';
PSpinner.defaultProps = {};

export default PSpinner;
