import React, { RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import PButton from 'views/PButton/PButton';
import PIcon from 'views/PIcon/PIcon';

import classes from './AppModal.module.scss';
import { ButtonType } from 'views/PButton/PButton.model';

type Props = {
  modalRef?: RefObject<HTMLDivElement>;
  show?: boolean;
  header?: string | JSX.Element | JSX.Element[];
  body: string | JSX.Element | JSX.Element[];
  showDefaultButtons?: boolean;
  showCloseIcon?: boolean;
  confirmText?: string;
  cancelText?: string;
  headerClassName?: string;
  windowWidth: number;
  windowHeight: number;
  switchButtonsActions?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onClickCloseModal?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const AppModalView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return props.show ? (
    <div className={classes['container']} style={{ width: props.windowWidth, height: props.windowHeight }}>
      <div ref={props.modalRef} className={classes['content']}>
        {props.showCloseIcon ? (
          <PButton type={ButtonType.Button} color="transparent" className={classes['close']} onClick={props.onClickCloseModal}>
            <PIcon name="close" className={classes['close__icon']}></PIcon>
          </PButton>
        ) : (
          <div className={classes['close']}></div>
        )}
        <div className={classes['information']}>
          <span className={`${classes['header']} ${props.headerClassName || ''}`.trim()}>{props.header}</span>
          {props.body}
          {props.showDefaultButtons ? (
            props.switchButtonsActions ? (
              <div className={classes['footer']}>
                <PButton type={ButtonType.Button} color="transparent" onClick={props.onConfirm}>
                  {props.confirmText || t('views.modal.footer.confirmBtn')}
                </PButton>
                <PButton type={ButtonType.Button} color="secondary" onClick={props.onCancel}>
                  {props.cancelText || t('views.modal.footer.cancelBtn')}
                </PButton>
              </div>
            ) : (
              <div className={classes['footer']}>
                <PButton type={ButtonType.Button} color="transparent" onClick={props.onCancel}>
                  {props.cancelText || t('views.modal.footer.cancelBtn')}
                </PButton>
                <PButton type={ButtonType.Button} color="secondary" onClick={props.onConfirm}>
                  {props.confirmText || t('views.modal.footer.confirmBtn')}
                </PButton>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
};

AppModalView.displayName = 'AppModalView';
AppModalView.defaultProps = {};

export default AppModalView;
