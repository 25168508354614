import React from 'react';

import PButtonView from './PButton.view';

import { ThemeColors } from 'models/general';
import { ButtonType } from './PButton.model';

type Props = {
  className?: string;
  color: ThemeColors;
  disabled?: boolean;
  type: ButtonType;
  to?: string;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const PButton = (props: React.PropsWithChildren<Props>): JSX.Element => {
  return (
    <PButtonView
      buttonRef={props.buttonRef}
      color={props.color}
      disabled={props.disabled}
      className={props.className}
      type={props.type}
      to={props.to}
      onClick={props.onClick}
    >
      {props.children}
    </PButtonView>
  );
};

PButton.displayName = 'PButton';
PButton.defaultProps = {};

export default PButton;
