import React from 'react';

import classes from './PIcon.module.scss';
import icons from '../../assets/icons';
import { IconSize } from './PIcon.model';

type Props = {
  name: keyof typeof icons;
  size?: IconSize;
  className?: string;
};

const PIconView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <svg
      className={`${classes['container']} ${props.size ? classes[`container--${props.size}`] : ''} ${props.className || ''}`.trim()}
      version="1.1"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 ' + icons[props.name][0]}
      dangerouslySetInnerHTML={{ __html: icons[props.name][1] }}
    ></svg>
  );
};

PIconView.displayName = 'PIconView';
PIconView.defaultProps = {};

export default PIconView;
