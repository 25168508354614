const auth = {
  welcome: 'login to rapid ir',
  loadingServer: 'Connecting to server',

  connectionError: {
    header: 'Unable to connect to {{env}}',
    body: 'Please contact your customer support',
  },

  form: {
    fields: {
      username: {
        title: 'email',
        placeholder: 'enter your email',
      },

      password: {
        title: 'password',
        placeholder: '************',
      },
    },

    validations: {
      username: {
        required: 'Email is required',
      },

      password: {
        required: 'Password is required',
      },
    },

    loginBtn: 'next',
    error: 'The email or password are incorrect.',
    tryAgain: 'Please try again',
  },

  footer: {
    troubleText: 'having trouble to log in?',
    troubleBtnText: 'click here',
  },

  loginTroubleModal: {
    header: 'Trouble logging in?',
    body: 'If this is an emergency, please use one of the alternate emergency communication channels. If you have trouble logging in, please contact your customer support.',
  },

  userStillPendingModal: {
    header: 'user is still pending',
    body: 'For any assistance please contact your Customer Success Manager in the relevant communication channel. if this is an emergency, please call the emergency hotline provided to you by the Profero team.',
  },
};

export default auth;
